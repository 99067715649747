import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../Atoms/Inputs/Text'
import { v4 } from 'uuid'
import { useParams } from 'react-router-dom'
import { getParametersByDataModelId } from '../../../store/parameter/api'
import { getRulesByDataModelId } from '../../../store/rule/api'
import { RuleRow } from './RuleRow'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { ParameterRow } from './ParameterRow'

const entityType = 'catalog'
const entityURL = 'product-catalogs'
const DIRECTIONS = [{ name: 'Personal Loan', value: 1 }]

export const CreateProductCatalog = () => {
    const [dataModels, setDataModels] = useState([])
    const [entity, setEntity] = useState({
        name: '',
        direction: '1',
        internalId: '',
        globalParameters: [],
        rules: [],
        description: '',
    })
    const [parameters, setParameters] = useState([
        { name: 'Choose', value: null },
    ])
    const [globalParameters, setGlobalParameters] = useState([
        { name: 'Choose', value: null },
    ])

    const [rules, setRules] = useState([{ name: 'Choose', value: null }])
    const params = useParams()

    useEffect(() => {
        getParametersByDataModelId(
            { modelId: params.modelId },
            dataModels,
        ).then((_parameters) => {
            const list = _parameters
                .filter((item) => item.group !== 11)
                .map((item) => {
                    return { name: item.name, value: item._id }
                })
            setParameters([{ name: 'Choose', value: null }, ...list])
            const list1 = _parameters
                .filter((item) => item.group === 11) // Filter items where group equals 11
                .map((item) => {
                    return { name: item.name, value: item._id }
                })

            setGlobalParameters([{ name: 'Choose', value: null }, ...list1])
        })
        getRulesByDataModelId({ modelId: params.modelId }, dataModels).then(
            (_rules) => {
                const list = _rules.map((item) => {
                    return { name: item.name, value: item._id }
                })
                setRules([{ name: 'Choose', value: null }, ...list])
            },
        )
    }, [])

    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }

    const updateRuleById = (RuleEntity) => {
        setEntity({
            ...entity,
            rules: entity.rules.map((rule) => {
                if (rule.id === RuleEntity.id) {
                    return RuleEntity
                }
                return rule
            }),
        })
    }

    const addRuleRow = () => {
        setEntity({
            ...entity,
            rules: [
                ...entity.rules,
                {
                    id: v4(),
                    rule: '',
                },
            ],
        })
    }
    const removeRule = (id) => {
        setEntity({
            ...entity,
            rules: entity.rules.filter((rule) => rule.id !== id),
        })
    }

    const updateParameterById = (ParameterEntity) => {
        setEntity({
            ...entity,
            globalParameters: entity.globalParameters.map((parameter) => {
                if (parameter.id === ParameterEntity.id) {
                    return ParameterEntity
                }
                return parameter
            }),
        })
    }

    const addParameterRow = () => {
        setEntity({
            ...entity,
            globalParameters: [
                ...entity.globalParameters,
                {
                    id: v4(),
                    parameter: '',
                    value: '',
                },
            ],
        })
    }
    const removeParameter = (id) => {
        setEntity({
            ...entity,
            globalParameters: entity.globalParameters.filter(
                (parameter) => parameter.id !== id,
            ),
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="single-table-wrapper rule-content">
                        <div className="inputsWrapper">
                            <div className="row first-row">
                                <TextInput
                                    label="Name"
                                    value={entity?.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <TextInput
                                    label="Internal Id"
                                    value={entity?.internalId}
                                    onChange={({ target }) => {
                                        onEntityChange(
                                            'internalId',
                                            target.value,
                                        )
                                    }}
                                    placeholder="Internal Id"
                                />
                                <SelectWithLabelInput
                                    label="Type"
                                    defaultValue={entity?.direction}
                                    onChange={({ target }) => {
                                        onEntityChange('type', target.value)
                                    }}
                                    options={DIRECTIONS}
                                />
                                <label>
                                    Description
                                    <textarea
                                        value={entity?.description}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'description',
                                                target.value,
                                            )
                                        }}
                                        placeholder="Description"
                                        style={{
                                            width: '100%',
                                            borderRadius: '7px',
                                            marginTop: '7px',
                                        }}
                                        rows="2"
                                        cols="25"
                                    />
                                </label>
                            </div>
                            <div className="row second-row catalog-main-content">
                                <div className="rules">
                                    <div className="resultTitle"> Rules</div>
                                    {entity.rules.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="conditions"
                                            >
                                                <RuleRow
                                                    updateRuleById={
                                                        updateRuleById
                                                    }
                                                    removeRuleById={removeRule}
                                                    rule={item}
                                                    rules={rules}
                                                />
                                            </div>
                                        )
                                    })}
                                    <ButtonWithIcon
                                        onClick={addRuleRow}
                                        icon="/assets/images/icon+.png"
                                        green={true}
                                        value="Add Rule"
                                    />
                                </div>
                                <div className="parameters">
                                    <div className="resultTitle">
                                        {' '}
                                        Global Parameters
                                    </div>
                                    {entity.globalParameters.map(
                                        (item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="conditions"
                                                >
                                                    <ParameterRow
                                                        updateParameterById={
                                                            updateParameterById
                                                        }
                                                        removeParameterById={
                                                            removeParameter
                                                        }
                                                        parameter={item}
                                                        parameters={
                                                            globalParameters
                                                        }
                                                    />
                                                </div>
                                            )
                                        },
                                    )}
                                    <ButtonWithIcon
                                        onClick={addParameterRow}
                                        icon="/assets/images/icon+.png"
                                        green={true}
                                        value="Add Parameter"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
