import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../../../Styles/list-Page.scss'
import editGreen from './../../../imgs/EditGreen.png'
import Delete from './../../../imgs/recycle.png'
import View from './../../../imgs/view.png'
import setingGreen from './../../../imgs/SettingGreen.png'
import { getDataById, getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import { FlowDetail } from '../FlowList/FlowDetail'
import { formatDate } from '../../../Services/dateFormat'
import ListPage from '../../Organisms/ListPage/ListPage'
import { SubProductCatalogListHeader } from './SubProductCatalogListHeader'
import { SubProductCatalogDetails } from './SubProductCatalogDetails'
import { getEntityVersionById } from '../../../store/common/api'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    archiveSubProductCatalogById,
    getSubProductCatalogsByDataModelId,
} from '../../../store/subProductCatalog/api'
const entityType = 'catalog'
export const SubProductCatalogList = (props) => {
    const [showPopup, setShowPopup] = useState(false)
    const [archivePopupId, setArchivePopupId] = useState(null)
    const [name, setName] = useState()
    const [key, setKey] = useState()
    const [versions, setVersions] = useState()
    const [dataModel, setDataModel] = useState()
    const [dataModels, setDataModels] = useState([])
    const [subProductCatalogDetail, setSubProductCatalogDetail] = useState(null)
    const DIRECTIONS = {
        1: 'Personal Loan',
    }
    const STATUSES_BY_KEY = {
        0: 'Archived',
        1: 'Draft',
        2: 'In review',
        3: 'Approved',
        4: 'Declined',
    }
    const history = useHistory()

    useEffect(() => {
        getDataModel().then((_dataModels) => {
            setDataModels(_dataModels)
            setDataModel(_dataModels?.[0]?._id)
        })
    }, [])
    const openSubProductCatalogDetail = (id) => {
        getEntityVersionById(id, entityType)
            .then((versions) => {
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setSubProductCatalogDetail(versions[0])
                }
            })
            .catch(handleError)
    }
    const viewSubProductCatalog = (item) => {
        history.push(`${window.location.pathname}/${item.data._id}?type=view`)
    }

    const deleteSubProductCatalog = () => {
        archiveSubProductCatalogById(archivePopupId)
            .then((versions) => {
                setArchivePopupId(null)
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setVersions(versions[0])
                }
                toast.success('Sub Product Catalog deleted successfully')
            })
            .catch(handleError)
    }

    const editDataItem = (item) => {
        getDataById({ id: item.data.modelId })
            .then((dataById) => {
                setShowPopup(true)
                setName(item.data.name)
                setVersions(item.data.version)
                setKey(item.data.key)
            })
            .catch(handleError)
    }
    const closeSubProductCatalogDetail = () => {
        setSubProductCatalogDetail(false)
    }

    const subProductCatalogTableColumns = [
        { field: 'name', filter: true },

        {
            headerName: 'Parent',
            field: 'parent',
            filter: true,
            valueGetter: (params) => {},
        },
        {
            headerName: 'Last Modified By',
            field: 'user',
            filter: 'agDateColumnFilter',
        },
        {
            headerName: 'Last Modified Date',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            src={editGreen}
                            onClick={() => editDataItem(params)}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={setingGreen}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                openSubProductCatalogDetail(params.data._id)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                        <img
                            src={Delete}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setArchivePopupId(params.data._id)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={View}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewSubProductCatalog(params)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                )
            },
        },
    ]
    return (
        <>
            <ArchivePopup
                isOpen={!!archivePopupId}
                togglePanel={setArchivePopupId}
                onDelete={() => deleteSubProductCatalog()}
            ></ArchivePopup>
            <div className={'tableWrapper'}>
                <div className={'table-Wrapper'}>
                    {subProductCatalogDetail ? (
                        <SubProductCatalogDetails
                            details={subProductCatalogDetail}
                            versions={versions}
                            close={() => {
                                setSubProductCatalogDetail(null)
                            }}
                        />
                    ) : null}
                    {dataModel ? (
                        <ListPage
                            pg="20"
                            triggerCall={dataModel}
                            dataModelCall={getDataModel}
                            dataCall={() =>
                                getSubProductCatalogsByDataModelId(
                                    { modelId: dataModel },
                                    dataModels,
                                )
                            }
                            tableColumns={subProductCatalogTableColumns}
                            parameterPanel={FlowDetail}
                            productCatalogDetail={subProductCatalogDetail}
                            setProductCatalogDetail={setSubProductCatalogDetail}
                            closeProductCatalogDetail={
                                closeSubProductCatalogDetail
                            }
                            setOpenPanel={props.setOpenPanel}
                            editDataItem={editDataItem}
                            HeaderComponent={() => (
                                <SubProductCatalogListHeader
                                    dataModel={dataModel}
                                    setDataModel={setDataModel}
                                    dataModels={dataModels}
                                />
                            )}
                        />
                    ) : null}
                </div>
            </div>
        </>
    )
}
